<template>
  <!-- <div class="map-block" :key="'map-block-itself-' + loadCounter"> -->
  <div class="map-block">
    <div id="map" class="map" />
  </div>
</template>

<script>
import ymaps from "ymaps";
import { myMixin } from "./map-function.js";

export default {
  name: "HomeMap",
  mixins: [myMixin],
  props: {
    points: {
      type: Array,
      default: () => [],
    },
    orderById: {
      type: Object,
      default: () => ({}),
    },
    historyPointsRaw: {
      type: Array,
      default: () => [],
    },
    trackId: {
      type: Number,
      default: null,
    },
    eventsToggle: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      map: null,
      maps: null, // ymaps
      cluster: null,
      routeList: [],
      routeListFull: [],
      location: 0,
      arrays: [],
      historyPoints: [],
      activeTrack: null,
      newActiveTrack: null,
      activeRedTrack: [],
      newActiveRedTrack: null,
      newdriverPlacemark: null,
      loadCounter: 0,
      trackEvents: null,
      // trackerProductRequested: false,
    };
  },
  watch: {
    historyPointsRaw() {
      this.$emit("loaded", ++this.loadCounter);
    },
    trackId() {
      if (this.historyPoints && this.historyPoints.length > 0) {
        this.newActiveTrack = new this.maps.Polyline(
          this.historyPoints.slice(0, this.trackId + 1),
          {
            geodesic: true,
          },
          {
            strokeWidth: 5,
            strokeColor: "#1E98FF",
            zIndex: 9,
          }
        );
        
        this.map.geoObjects.add(this.newActiveTrack);
        this.map.geoObjects.remove(this.activeTrack);
        this.activeTrack = this.newActiveTrack;

        for (const redTrackVal of this.activeRedTrack) {
          this.map.geoObjects.remove(redTrackVal);
        }
        
        this.activeRedTrack = [];

        let intervalIncorrectTemp = [];

        this.historyPoints.slice(0, this.trackId + 1).filter((pointItem, index) => {
          if (index != 0 && pointItem[2] > 7) {
            if (intervalIncorrectTemp.length == 0) {
              intervalIncorrectTemp.push([pointItem]);
            } else {
              if (this.historyPoints[index - 1][2] > 7) {
                intervalIncorrectTemp[intervalIncorrectTemp.length - 1].push(pointItem);
              } else {
                intervalIncorrectTemp.push([pointItem]);
              }
            }
          }
        });

        for (const pointValues of intervalIncorrectTemp) {
            this.newActiveRedTrack = new this.maps.Polyline(
              pointValues,
              {
                geodesic: true,
              },
              {
                strokeWidth: 5,
                strokeColor: "#FF0000",
                zIndex: 10,
              }
            );
            this.activeRedTrack.push(this.newActiveRedTrack);
          }

          for (const redTrackVal of this.activeRedTrack) {
            this.map.geoObjects.add(redTrackVal);
          }


        if (this.driverPlacemark) {
          this.driverPlacemark.geometry.setCoordinates(this.historyPoints[this.trackId]);
          this.driverPlacemark.properties.set("balloonContent", this.driverBalloonContent);
          this.driverPlacemark.options.set("zIndex", 110);
        } else {
          this.driverPlacemark = new this.maps.Placemark(
            this.historyPoints[this.trackId],
            {
              hintContent: `${this.orderById.transport.transport.label}`,
              balloonContent: this.driverBalloonContent,
            },
            {
              preset: "islands#blueAutoIcon",
              zIndex: 110,
            }
          );

          this.driverPlacemark.geometry.setCoordinates(this.historyPoints[this.trackId]);
        }

        this.map.geoObjects.add(this.driverPlacemark);

        if (this.eventsToggle) {
          if (this.trackEvents) {
            this.trackEvents.removeAll();
          }
          this.renderEvents();
        }
      }
    },

    eventsToggle() {
      if (this.trackEvents) {
        this.trackEvents.removeAll();
      }
      if (this.eventsToggle) {
        this.renderEvents();
      }
    },
  },
  computed: {
    driverBalloonContent() {
      return `
              <div class="balloon-header">
                <div class="balloon-header__main">
                  <div class="balloon-header__title">${
                    this.orderById.transport.transport.label || "-"
                  }</div>
                </div>
              </div>
              <div class="balloon-body">
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Скорость</div>
                    <div class="balloon-property-value">${
                      this.historyPointsRaw[this.trackId].speed || "-"
                    } км/ч</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64zM256 416C291.3 416 320 387.3 320 352C320 334.6 313.1 318.9 301.9 307.4L365.1 161.7C371.3 149.5 365.8 135.4 353.7 130C341.5 124.7 327.4 130.2 322 142.3L257.9 288C257.3 288 256.6 287.1 256 287.1C220.7 287.1 192 316.7 192 352C192 387.3 220.7 416 256 416V416zM144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112zM96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288zM416 224C398.3 224 384 238.3 384 256C384 273.7 398.3 288 416 288C433.7 288 448 273.7 448 256C448 238.3 433.7 224 416 224z"/></svg>
                  </div>
                </div>
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Топливо</div>
                    <div class="balloon-property-value">${
                      this.historyPointsRaw[this.trackId].oil || "-"
                    } л</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M32 64C32 28.65 60.65 0 96 0H256C291.3 0 320 28.65 320 64V256H328C376.6 256 416 295.4 416 344V376C416 389.3 426.7 400 440 400C453.3 400 464 389.3 464 376V221.1C436.4 214.9 416 189.8 416 160V96L384 64C375.2 55.16 375.2 40.84 384 32C392.8 23.16 407.2 23.16 416 32L493.3 109.3C505.3 121.3 512 137.5 512 154.5V376C512 415.8 479.8 448 440 448C400.2 448 368 415.8 368 376V344C368 321.9 350.1 303.1 328 303.1H320V448C337.7 448 352 462.3 352 480C352 497.7 337.7 512 320 512H32C14.33 512 0 497.7 0 480C0 462.3 14.33 448 32 448V64zM96 176C96 184.8 103.2 192 112 192H240C248.8 192 256 184.8 256 176V80C256 71.16 248.8 64 240 64H112C103.2 64 96 71.16 96 80V176z"/></svg>
                  </div>
                </div>
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Температура</div>
                    <div class="balloon-property-value">${
                      this.historyPointsRaw[this.trackId].temp || "-"
                    } °C</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512"><path d="M176 322.9V160c0-8.75-7.25-16-16-16s-16 7.25-16 16v162.9c-18.62 6.625-32 24.25-32 45.13c0 26.5 21.5 48 48 48s48-21.5 48-48C208 347.1 194.6 329.5 176 322.9zM272 278.5V112c0-61.87-50.12-112-111.1-112S48 50.13 48 112v166.5c-19.75 24.75-32 55.5-32 89.5c0 79.5 64.5 143.1 144 143.1S304 447.5 304 368C304 334 291.8 303.1 272 278.5zM160 448c-44.13 0-80-35.87-80-79.1c0-25.5 12.25-48.88 32-63.75v-192.3c0-26.5 21.5-48 48-48s48 21.5 48 48v192.3c19.75 14.75 32 38.25 32 63.75C240 412.1 204.1 448 160 448z"/></svg>
                  </div>
                </div>
                <div class="balloon-property">
                  <div class="balloon-property-main">
                    <div class="balloon-property-label">Одометр</div>
                    <div class="balloon-property-value">${
                      this.historyPointsRaw[this.trackId].odometr || "-"
                    } км</div>
                  </div>
                  <div class="balloon-property-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256zM256 64C238.3 64 224 78.33 224 96C224 113.7 238.3 128 256 128C273.7 128 288 113.7 288 96C288 78.33 273.7 64 256 64zM256 416C291.3 416 320 387.3 320 352C320 334.6 313.1 318.9 301.9 307.4L365.1 161.7C371.3 149.5 365.8 135.4 353.7 130C341.5 124.7 327.4 130.2 322 142.3L257.9 288C257.3 288 256.6 287.1 256 287.1C220.7 287.1 192 316.7 192 352C192 387.3 220.7 416 256 416V416zM144 112C126.3 112 112 126.3 112 144C112 161.7 126.3 176 144 176C161.7 176 176 161.7 176 144C176 126.3 161.7 112 144 112zM96 288C113.7 288 128 273.7 128 256C128 238.3 113.7 224 96 224C78.33 224 64 238.3 64 256C64 273.7 78.33 288 96 288zM416 224C398.3 224 384 238.3 384 256C384 273.7 398.3 288 416 288C433.7 288 448 273.7 448 256C448 238.3 433.7 224 416 224z"/></svg>
                  </div>
                </div>
                </div>
              </div>`;
    },
  },

  created() {
    for (const point of this.historyPointsRaw) {
      this.historyPoints.push([point.lat, point.lng, point.temp]);
    }
  },
  mounted() {
    // How you should do this probably
    if (this.points && this.points.length > 0) {
      let sumIndex = 0;
      const tempArr = [];
      this.points.forEach((point, i) => {
        if (point && point.lat && point.long) {
          tempArr.push(point.lat, point.long);
          if (
            !this.routeList.some(
              (r) =>
                r.length === tempArr.length && r.every((value, index) => tempArr[index] === value)
            )
          ) {
            this.routeList[sumIndex] = [...tempArr];
            this.routeListFull[sumIndex++] = point;
          }
          tempArr.length = 0;
        }
        if (point) point.status === "success" && (this.location = i);
      });
    }
    ymaps
      .load(
        `https://api-maps.yandex.ru/2.1/?apikey=${process.env.VUE_APP_YMAPS_APIKEY}&lang=ru_RU`,
        "https://yastatic.net/s3/mapsapi-jslibs/heatmap/0.0.1/heatmap.min.js"
      )
      .then((maps) => {
        this.maps = maps;
        // создание карты
        this.map = new maps.Map(
          "map",
          // настройки окна ymaps
          {
            center: this.routeList.length > 0 ? this.routeList[this.location] : [50.9797, 71.3295],
            zoom: 11,
            controls: ["geolocationControl", "zoomControl"],
          },
          {
            geolocationControlFloat: "right",
            geolocationControlPosition: {
              bottom: "200px",
              right: "10px",
            },
            geolocationControl: "none",
            zoomControlFloat: "none",
            zoomControlPosition: {
              bottom: "125px",
              right: "10px",
            },
            zoomControlSize: "small",
          }
        );

        if (this.points && this.routeList.length > 0) {
          this.routeIcon(maps, this.routeList);
          this.route(maps, this.routeList.slice(0, this.location), this.routeList[this.location]);

          this.route(
            maps,
            this.routeList[this.location],
            this.routeList.slice(this.location + 1, this.routeList.length),
            false
          );
        }
        if (this.historyPoints && this.historyPoints.length > 0 && this.orderById.transport) {

          let intervalIncorrectTemp = [];

          this.historyPoints.filter((pointItem, index) => {
            if (index != 0 && pointItem[2] > 7) {
              if (intervalIncorrectTemp.length == 0) {
                intervalIncorrectTemp.push([pointItem]);
              } else {
                if (this.historyPoints[index - 1][2] > 7) {
                  intervalIncorrectTemp[intervalIncorrectTemp.length - 1].push(pointItem);
                } else {
                  intervalIncorrectTemp.push([pointItem]);
                }
              }
            }
          });

          for (const pointValues of intervalIncorrectTemp) {
            this.newActiveRedTrack = new maps.Polyline(
              pointValues,
              {
                geodesic: true,
              },
              {
                strokeWidth: 5,
                strokeColor: "#FF0000",
                zIndex: 10,
              }
            );

            this.activeRedTrack.push(this.newActiveRedTrack);
          }

          for (const redTrackVal of this.activeRedTrack) {
            this.map.geoObjects.add(redTrackVal);
          }
          
          const trackHistoryLine = new maps.Polyline(
            this.historyPoints,
            {
              geodesic: true,
            },
            {
              strokeWidth: 3,
              strokeColor: "#aaaaccaa",
              zIndex: 1,
            }
          );

          this.map.geoObjects.add(trackHistoryLine);

          this.newActiveTrack = new this.maps.Polyline(
            this.historyPoints.slice(0, this.trackId + 1),
            {
              geodesic: true,
            },
            {
              strokeWidth: 3.5,
              strokeColor: "#1E98FF",
              zIndex: 9,
            }
          );

          this.activeTrack = this.newActiveTrack;
          this.map.geoObjects.add(this.activeTrack);

        

          this.driverPlacemark = new this.maps.Placemark(
            this.historyPoints[this.trackId],
            {
              hintContent: `${this.orderById.transport.transport.label}`,
              balloonContent: this.driverBalloonContent,
            },
            {
              preset: "islands#blueAutoIcon",
            }
          );

          this.driverPlacemark.events.add("balloonopen", () => {
            this.driverPlacemark.properties.set("balloonContent", this.driverBalloonContent);
          });

          this.map.geoObjects.add(this.driverPlacemark);
        }
        this.$nextTick(() => {
          this.$emit("loaded", ++this.loadCounter);
        });
      })
      .catch((error) =>
        console.error(error)
      );
  },
  methods: {
    formatDate(dateStr) {
      const date = new Date(dateStr);
      date.toLocaleString("ru-RU", { timeZone: "Asia/Qyzylorda" });
      return date.toISOString().slice(0, 19).replace("T", " ");
    },
    fuelBalloon(message, label, time, address) {
      return `<div class="balloon-header">
                <div class="balloon-header__main">
                  <div class="balloon-header__title">${label || "-"}</div>
                  <div class="balloon-header__title">${message || "-"}</div>
                  <div class="balloon-header__title">${time || "-"}</div>
                  <div class="balloon-header__title">${address || "-"}</div>
                </div>
              </div>`;
    },
    renderEvents() {
      let eventsDt = this.$store.state.OrderTrackEvents;

      this.trackEvents = new this.maps.GeoObjectCollection({});

      eventsDt.forEach((item) => {
        if (
          item.event_type == "refueling_drain" &&
          item.time > this.formatDate(this.historyPointsRaw[0].created_at) &&
          item.time < this.formatDate(this.historyPointsRaw[this.trackId].created_at)
        ) {
          this.trackEvents.add(
            new this.maps.Placemark(
              [item.lat, item.lng],
              {
                hintContent: "",
                balloonContent: `${this.fuelBalloon(
                  item.message,
                  item.label,
                  item.time,
                  item.address
                )}`,
              },
              {
                preset: "islands#redFuelStationIcon",
                zIndex: 1,
              }
            )
          );
        } else if (
          item.event_type == "refueling" &&
          item.time > this.formatDate(this.historyPointsRaw[0].created_at) &&
          item.time < this.formatDate(this.historyPointsRaw[this.trackId].created_at)
        ) {
          this.trackEvents.add(
            new this.maps.Placemark(
              [item.lat, item.lng],
              {
                hintContent: "",
                balloonContent: `${this.fuelBalloon(
                  item.message,
                  item.label,
                  item.time,
                  item.address
                )}`,
              },
              {
                preset: "islands#blueFuelStationIcon",
                zIndex: 1,
              }
            )
          );
        } else if (
          item.event_type == "battery_off" &&
          item.time > this.formatDate(this.historyPointsRaw[0].created_at) &&
          item.time < this.formatDate(this.historyPointsRaw[this.trackId].created_at)
        ) {
          this.trackEvents.add(
            new this.maps.Placemark(
              [item.lat, item.lng],
              {
                hintContent: "",
                balloonContent: `${this.fuelBalloon(
                  item.message,
                  item.label,
                  item.time,
                  item.address
                )}`,
              },
              {
                preset: "islands#grayAttentionIcon",
                zIndex: 1,
              }
            )
          );
        } else if (
          item.event_type == "harsh_driving" &&
          item.time > this.formatDate(this.historyPointsRaw[0].created_at) &&
          item.time < this.formatDate(this.historyPointsRaw[this.trackId].created_at)
        ) {
          this.trackEvents.add(
            new this.maps.Placemark(
              [item.lat, item.lng],
              {
                hintContent: "",
                balloonContent: `${this.fuelBalloon(
                  item.message,
                  item.label,
                  item.time,
                  item.address
                )}`,
              },
              {
                preset: "islands#redAttentionIcon",
                // iconLayout: "default#image",
                // iconImageHref: '../img/icons/danger.svg',
                // iconImageSize: [20, 20],
                // iconImageOffset: [-10, -10],
                zIndex: 1,
              }
            )
          );
        }
      });

      if (this.trackEvents) {
        this.map.geoObjects.add(this.trackEvents);
      }
    },
  },
};
</script>

<style>
/* .driverBalloon {
  width: max-content;
} */

.balloon-header {
  display: flex;
  justify-content: space-between;
  min-width: max-content;
  width: 100%;
  font-weight: 500;
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
}
.balloon-header__number {
  border-radius: 12px;
  background-color: #e1e8fa;
  color: #5b6fa2;
  display: flex;
  align-items: center;
  padding: 0.35rem;
  margin-left: 1rem;
}
.balloon-header__main {
  flex-grow: 1;
}
.balloon-header__driver {
  opacity: 0.6;
  font-size: 0.85em;
  display: flex;
  align-content: center;
}
/* .balloon-header__driver::after {
  content: "";
  display: block;
  flex-shrink: 2;
  flex-grow: -1;
  flex-basis: 100px;
} */
.balloon-header__driver-name {
  flex-grow: 5;
}
.balloon-header__driver-phone {
  flex-grow: 3;
}
.balloon-header__driver-icon {
  flex-basis: 1rem;
  margin-right: 0.3rem;
}
.balloon-header__driver-icon svg {
  max-height: 1rem;
}
.balloon-body {
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 0.5rem;
  min-width: max-content;
  width: 100%;
}
.balloon-property {
  padding: 0.5rem;
  display: flex;
  border-radius: 12px;
  background-color: #f3f2fa;
}
.balloon-property-main {
  flex-grow: 1;
  margin-right: 0.5rem;
}
.balloon-property-label {
  opacity: 0.6;
  font-size: 0.9em;
}
.balloon-property-value {
  font-size: 1.15em;
}
.balloon-property-icon {
  display: flex;
  align-items: center;
  opacity: 0.5;
}
.balloon-property-icon svg {
  width: 1rem;
}
.balloon-footer {
  font-family: "Product Sans", Arial, Helvetica, sans-serif;
  font-weight: 500;
  width: 100%;
  min-width: max-content;
}
</style>

<style scoped>
.map-block {
  max-width: 100%;
  max-height: 100%;
  /* height: 300px;
  width: 400px; */
  position: relative;
}
.map {
  width: 100%;
  height: 100%;
  border-radius: 20px;
}
.map__control {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 1;
}
@media (max-width: 579px) {
  .map-block {
    width: 100%;
  }

  .map {
    height: calc(100% - 50px);
  }
}

/* // methods: {
  //   async trackerProduct() {
  //     if (this.trackerProductRequested === false) {
  //       if (this.orderById.transport) {
  //         this.trackerProductRequested = true;
  //         await this.$store.dispatch("getTrackerId", {
  //           tracker_id: this.orderById.transport.transport.tracker_id,
  //         });
  //       } else {
  //         this.$store.commit("setTrackerId", null);
  //       }
  //     }
  //   },
  // }, */
</style>
